import { FunctionComponent } from "react";

interface JupyterViewerProps {
    rawIpynb: {
        cells: any[],
        nbformat: number,
        nbformat_minor: number,
        metadata: any
    }
}

const JupyterViewer: FunctionComponent<JupyterViewerProps> = ({}: JupyterViewerProps) => {
    
    return (<></>);
}

export default JupyterViewer;