import { FunctionComponent } from 'react';
import PageLogo from '../../assets/PageLogo.png';

import './FloatingPageLogo.css';

interface FloatingPageLogoProps {}

const FloatingPageLogo: FunctionComponent<FloatingPageLogoProps> = () => {
  return (
    <div className="logo-container">
      <img className="logo" src={PageLogo} alt=""></img>
    </div>
  );
};

export default FloatingPageLogo;
