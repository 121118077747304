import { DataInfoApi } from '../components/DataInfoForm/DataInfoForm';
import {
  DataCleanApi,
  DataFilterApi,
} from '../components/DataInfoTable/DataInfoTable';
import { ACTIONS, AppAction } from './actions';
import { AppState, PROCESSING_METHOD, ScaledColumn } from './state';

export const reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case ACTIONS.LOGIN:
      const username = action.payload.username;
      const token = action.payload.token;
      localStorage.setItem('username', JSON.stringify(username));
      localStorage.setItem('token', JSON.stringify(token));
      return {
        ...state,
        isAuthenticated: true,
        username,
        token,
      };
    case ACTIONS.LOGOUT:
      localStorage.removeItem('username');
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        username: null,
        token: null,
      };
    case ACTIONS.RESET:
      return {
        ...state,
        currentCsv: null,
        qod: null,
        ai: null,
      };
    case ACTIONS.QOD_SET_INFO:
      const infoData = action.payload.data as DataInfoApi;
      const description = action.payload.description as string;
      const file = action.payload.file as File;

      return {
        ...state,
        currentCsv: file,
        qod: {
          description,
          current: {
            rows: infoData['Number of rows'],
            columns: infoData['Number of columns'],
            qualityPercent: infoData['% of Good rows'],
            outlierPercent: infoData['% of Outliers'] || 0,
            duplicatePercent:
              infoData['% of Duplicate rows'] ||
              state.qod?.current.duplicatePercent ||
              state.qod?.initial.duplicatePercent ||
              0,
            nullPercent:
              infoData['% of NULL rows'] ||
              state.qod?.current.nullPercent ||
              state.qod?.initial.nullPercent ||
              0,
            
          },
          initial: {
            rows: infoData['Number of rows'],
            columns: infoData['Number of columns'],
            duplicatePercent: infoData['% of Duplicate rows'],
            nullPercent: infoData['% of NULL rows'],
            goodPercent: infoData['% of Good rows'],
            outlierPercent: infoData['% of Outliers'],
            columnDetails: infoData['Column details'].map((details) => {
              return {
                name: details['Column name'],
                datatype: details['Data Type'],
                type: details['Type'].toLowerCase() as 'unique' | 'min_max',
                uniqueValues: details['Unique Values'],
                maxValue: details['Max value'],
                minValue: details['Min value'],
                mean: 0,
                median: 0,
                mode: 0,
              };
            }),
          },
        },
      };
    case ACTIONS.QOD_CLEAN_INFO:
      const cleanData = action.payload.data as DataCleanApi;
      const newCurrentCleanedState = {
        ...state.qod?.current,
        rows: cleanData['Number of rows after cleaning'] || 0,
        qualityPercent: cleanData['% of Good rows'] || 0,
        duplicatePercent: cleanData['% of Duplicate rows'] || 0,
        nullPercent: cleanData['% of NULL rows'] || 0,
        outlierPercent: cleanData['% of Outliers'] || 0,
      };
      const newCleanedState = {
        ...state.qod,
        current: newCurrentCleanedState,
        cleaned: {
          removedDuplicatePercent:
            cleanData['% of Duplicate rows'] || 0,
          removedNullPercent: cleanData['% of NULL rows'] || 0,
          remainingRows: cleanData['Number of rows after cleaning'] || 0,
          outliers: cleanData['% of Outliers'] || 0,
        },
        encoded: null,
        lastProcessingMethod: PROCESSING_METHOD.CLEAN,
      };
      return {
        ...state,
        qod: newCleanedState as any,
      } as AppState;
    case ACTIONS.QOD_FILTER_INFO:
      const filterData = action.payload.data as DataFilterApi;
      const newCurrentFilterState = {
        ...state.qod?.current,
        rows: filterData['Number of rows after filtering'] || 0,
        qualityPercent: filterData['% of Good rows'] || 0,
        duplicatePercent: filterData['% of Duplicate rows'] || 0,
        nullPercent: filterData['% of NULL rows'] || 0,
      };
      const newFilteredState = {
        ...state.qod,
        current: newCurrentFilterState,
        filtered: {
          remainingRows: filterData['Number of rows after filtering'] || 0,
        },
        encoded: null,
        lastProcessingMethod: PROCESSING_METHOD.FILTER,
      };
      return {
        ...state,
        qod: newFilteredState as any,
      } as AppState;
    case ACTIONS.QOD_SCALE_INFO:
      const scaleData = action.payload.data;
      const newCurrentScaledState = {
        ...state.qod?.current,
        qualityPercent: scaleData['% of Good rows'] || 0,
        duplicatePercent: scaleData['% of Duplicate rows'] || 0,
        nullPercent: scaleData['% of NULL rows'] || 0,
      };
      const newScaledState = {
        ...state.qod,
        current: newCurrentScaledState,
        scaled: {
          beforeScaling: scaleData['Before_scaling'].map(
            (col: any): ScaledColumn => {
              return {
                name: col['Column Name'],
                max: col['Maximum'],
                min: col['Minimum'],
                mean: col['Mean'],
                standardDeviation: col['Standard Deviation'],
              };
            }
          ),
          afterScaling: scaleData['After_scaling'].map(
            (col: any): ScaledColumn => {
              return {
                name: col['Column Name'],
                max: col['Maximum'],
                min: col['Minimum'],
                mean: col['Mean'],
                standardDeviation: col['Standard Deviation'],
              };
            }
          ),
          method: action.payload.method,
        },
        encoded: null,
        lastProcessingMethod: PROCESSING_METHOD.SCALE,
      };
      return {
        ...state,
        qod: newScaledState as any,
      } as AppState;
    case ACTIONS.QOD_ENCODE_INFO:
      const encodeData = action.payload.data;
      const newCurrentEncodedState = {
        ...state.qod?.current,
        qualityPercent: encodeData['% of Good rows'] || 0,
        duplicatePercent: encodeData['% of Duplicate rows'] || 0,
        nullPercent: encodeData['% of NULL rows'] || 0,
      };
      const newEncodedState = {
        ...state.qod,
        current: newCurrentEncodedState,
        encoded: {
          columns: encodeData['Encoded_Columns'],
          method: action.payload.method,
        },
        lastProcessingMethod: PROCESSING_METHOD.ENCODE,
      };

      return {
        ...state,
        qod: newEncodedState as any,
      } as AppState;
    case ACTIONS.AI_UPLOAD_JUPYTER:
      const newAiState = {
        ...state.ai,
        rawIpynb: require('../assets/Iris_HPT.json'),
      };
      return {
        ...state,
        ai: newAiState as any,
      } as AppState;
    default:
      return state;
  }
};