export enum ACTIONS {
  'LOGIN',
  'LOGOUT',
  'RESET',
  'QOD_SET_INFO',
  'QOD_CLEAN_INFO',
  'QOD_FILTER_INFO',
  'QOD_SCALE_INFO',
  'QOD_ENCODE_INFO',
  'AI_UPLOAD_JUPYTER',
}

export type AppAction = {
  type: ACTIONS;
  payload: any;
};
