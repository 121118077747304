import './NavigationRail.css';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

type NavigationRailProps = {
  menuItems?: {
    icon: IconDefinition;
    label: string;
    navigateTo: string;
    onClick?: () => void;
  }[];
};

function NavigationRail({ menuItems }: NavigationRailProps) {
  function navButton() {
    return menuItems?.map((item) => (
      <li key={item.label} className="navigation-child">
        <Link to={item.navigateTo} className="navigation-link">
          <FontAwesomeIcon
            className="navigation-list-icon"
            icon={item.icon}
            style={{ width: '24px', height: '24px' }}
          ></FontAwesomeIcon>
          <span className="navigation-list-label">{item.label}</span>
        </Link>
      </li>
    ));
  }

  return <div className="navigation-rail">{navButton()}</div>;
}

export default NavigationRail;
