import OptionSelectHint from '../../components/OptionSelectHint/OptionSelectHint';
import './Home.css';
import TitleLogo from '../../assets/LogoHome.png';

function HomePage() {
  return (
    <div className="home-container">
      <OptionSelectHint />
      <div className="title-container">
        <img src={TitleLogo}></img>
      </div>
    </div>
  );
}

export default HomePage;
