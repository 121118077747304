import { useContext } from 'react';
import DataInfoForm, {
  APIINTERACTION,
} from '../../components/DataInfoForm/DataInfoForm';
import DataInfoTable from '../../components/DataInfoTable/DataInfoTable';
import { AppContext } from '../../state/state';
import './Qod.css';

function QodPage() {
  const context = useContext(AppContext);

  function renderComponent() {
    if (!context!.state?.qod) {
      return <DataInfoForm type={APIINTERACTION.INFO} />;
    } else {
      return <DataInfoTable />;
    }
  }

  return <div className="qod-container">{renderComponent()}</div>;
}

export default QodPage;
