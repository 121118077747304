import { FunctionComponent, useEffect } from 'react';
import './InfoGraph.css';

import * as d3 from 'd3';

import { PieArcDatum } from 'd3';

type Data = { quantity: number; label: string };
interface IInfoGraph {
  width: number;
  height: number;
  dataset: Data[];
}

const InfoGraph: FunctionComponent<IInfoGraph> = ({
  width,
  height,
  dataset,
}: IInfoGraph) => {
  // const [data, setData] = useState([]);

  dataset = dataset.filter((d) => {
    return d.quantity > 0;
  });

  const margin = { top: 20, right: 20, bottom: 20, left: 20 };
  const calcWidth = width - margin.left - margin.right;
  const calcHeight = height - margin.top - margin.bottom;

  function drawGraph() {
    const radius = Math.min(width, height) / 2 - margin.top;

    d3.select('.graph').selectAll('*').remove();

    const svg = d3
      .select('.graph')
      .append('svg')
      .attr('width', width)
      .attr('height', height + dataset.length * 30);

    const chart = svg
      .append('g')
      .attr(
        'transform',
        'translate(' + calcWidth / 2 + ',' + calcHeight / 2 + ')'
      );

    const color = d3
      .scaleOrdinal<string, string>()
      .range(d3.schemeAccent)
      .domain(
        dataset.map((d) => {
          return d.label;
        })
      );

    const pie = d3.pie<Data>().value((d): number => {
      return d.quantity;
    });
    const data_ready = pie(dataset);

    const arcGenerator = d3
      .arc<PieArcDatum<Data>>()
      .innerRadius(0)
      .outerRadius(radius);

    const legend = svg
      .append('g')
      .attr('transform', `translate(0,${radius * 2 + 20})`);

    chart
      .selectAll('mySlices')
      .data(data_ready)
      .join('path')
      .attr('d', (d) => arcGenerator(d))
      .attr('fill', (d) => {
        return color(d.data.label);
      })
      .attr('stroke', 'black')
      .style('stroke-width', '2px');

    const labelHeight = 18;

    legend
      .selectAll(null)
      .data(data_ready)
      .enter()
      .append('rect')
      .attr('y', (d) => labelHeight * d.index * 1.8)
      .attr('width', labelHeight)
      .attr('height', labelHeight)
      .attr('fill', (d) => color(d.data.label))
      .attr('stroke', 'grey')
      .style('stroke-width', '1px');

    legend
      .selectAll(null)
      .data(data_ready)
      .enter()
      .append('text')
      .text(
        (d) => `${d.data.label} (${Math.round(d.data.quantity * 1000) / 1000}%)`
      )
      .attr('x', labelHeight * 1.2)
      .attr('y', (d) => labelHeight * d.index * 1.8 + labelHeight)
      .style('font-family', 'sans-serif')
      .style('font-size', `${labelHeight}px`)
      .style('padding-left', '1em')
      .attr('fill', 'white');

    // svg
    //   .selectAll('mySlices')
    //   .data(data_ready)
    //   .join('text')
    //   .text((d) => {
    //     return (
    //       d.data.label + ' (' + Math.round(d.data.quantity * 10) / 10 + '%)'
    //     );
    //   })
    //   .attr('transform', (d) => {
    //     return `translate(${arcGenerator.centroid(d)})`;
    //   })
    //   .style('text-anchor', 'middle')
    //   .style('font-size', 17);

    // svg.append("g")
    //     .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // const selection = svg.selectAll("rect").data(data);
    // var yScale = d3.scaleLinear()
    //         .domain([0, d3.max(data) || 0])
    //         .range([0, height-100]);
    // selection
    //     .transition().duration(300)
    //         .attr("height", (d) => yScale(d))
    //         .attr("y", (d) => height - yScale(d))

    // selection
    //     .enter()
    //     .append("rect")
    //     .attr("x", (d, i) => i * 45)
    //     .attr("y", (d) => height)
    //     .attr("width", 40)
    //     .attr("height", 0)
    //     .attr("fill", "orange")
    //     .transition().duration(300)
    //         .attr("height", (d) => yScale(d))
    //         .attr("y", (d) => height - yScale(d))

    // selection
    //     .exit()
    //     .transition().duration(300)
    //         .attr("y", (d) => height)
    //         .attr("height", 0)
    //     .remove()
  }

  useEffect(() => {
    drawGraph();
  });

  return <span className="graph"></span>;
};

export default InfoGraph;
