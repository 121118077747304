import { useState, useContext, FormEvent, ChangeEvent, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import './Login.css';
import { ACTIONS } from "../../state/actions";
import { AppContext } from '../../state/state';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

function LoginPage() {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    const initialState = {
        username: "",
        password: "",
        isSubmitted: false,
        error: null
    }

    const [state, setState] = useState(initialState);

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        event.stopPropagation();
        const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IldlaCIsImlhdCI6MTUxNjIzOTAyMn0.t95Q9i4zoM-giIycu41aWJYvL5f7fAeNmGJczZr31J0";
        context.dispatch!({ type: ACTIONS.LOGIN, payload: { username: state.username, token } });
        navigate("/", { replace: true });
    }
    
    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        setState({ ...state, [target.name]: target.value })
    }

    useEffect(() => {
        if (context.state!.isAuthenticated) {
            navigate('/');
        }
    })

    return (
        <div className="login-container">
            <Form onSubmit={handleSubmit}>
                <FormGroup controlId="loginFormUsername">
                    <FormLabel>Username</FormLabel>
                    <FormControl
                        name="username"
                        required
                        type="text"
                        onChange={handleInputChange}
                        placeholder="Enter Username" />
                </FormGroup>
                <FormGroup controlId="loginFormPassword">
                    <FormLabel>Password</FormLabel>
                    <FormControl
                        name="password"
                        required
                        type="password"
                        onChange={handleInputChange}
                        placeholder="Enter Password" />
                </FormGroup>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    )
}

export default LoginPage;