import { FunctionComponent } from "react";
import './Loader.css';

type LoaderProps = {
    text?: string
}

export function Loader({text}: LoaderProps) {
    return (<span className="loader-container"><span className="loader"></span><span className="loaderText">{text}</span></span>)
}
