import { FunctionComponent, FormEvent, useContext, useState } from "react";

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { AppContext } from "../../state/state";
import { ACTIONS } from "../../state/actions";
import { Loader } from "../Loader/Loader";

interface JupyterUploadFormProps { }

const JupyterUploadForm: FunctionComponent<JupyterUploadFormProps> = ({}: JupyterUploadFormProps) => {
    const context = useContext(AppContext);

    const initialState: { isLoading?: boolean } = {
        isLoading: false,
    }

    const [state, setState] = useState(initialState);

    async function handleSubmit(event: FormEvent<HTMLFormElement>) {
        setState({ ...state, isLoading: true });

        event.preventDefault();
        event.stopPropagation();

        context.dispatch!({ type: ACTIONS.AI_UPLOAD_JUPYTER, payload: {}});
        setState({ ...state, isLoading: false });
    }

    return (
        <div className="info-form-container">
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <FormLabel>Upload Data File</FormLabel>
                    <FormControl
                        name="fileUpload"
                        type="file"
                        placeholder="Enter Username"
                        disabled={state.isLoading}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>File Description</FormLabel>
                    <FormControl
                        name="description"
                        type="text"
                        placeholder="Enter File Description"
                        disabled={state.isLoading}
                    />
                </FormGroup>
                <Button variant="primary" type="submit" disabled={state.isLoading}>
                    {state.isLoading ? <Loader text="Loading..." />: "Upload"}
                </Button>
            </Form>
        </div>
    );
}

export default JupyterUploadForm;