import { FormEvent, FunctionComponent, ReactNode, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { ColumnDetails } from '../../state/state';

import './FilterDataModal.css';

interface FilterDataModalProps {
  callbackSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  callbackClose: () => void;
  show: boolean;
  columnData: ColumnDetails[];
}

const FilterDataModal: FunctionComponent<FilterDataModalProps> = (
  props: FilterDataModalProps
) => {
  const initialState = {
    optionsState: filterOptionsState(),
  };

  const [state, setState] = useState(initialState);

  function filterOptionsState(): { [key: string]: boolean } {
    const boolList = props.columnData.map((column) => {
      return [column.name, false];
    });
    return Object.fromEntries(boolList);
  }

  function filterDropdownChildren(): ReactNode {
    return props.columnData.map((column) => {
      return (
        <Dropdown.Item
          as="button"
          type="button"
          onClick={() => toggleFilter(column.name)}
        >
          {column.name}
        </Dropdown.Item>
      );
    });
  }

  function filterOptionsList() {
    return (
      <>
        {props.columnData.map((column) => {
          let fields: JSX.Element;
          if (column.datatype === 'int64' || column.datatype === 'float64') {
            fields = (
              <InputGroup
                className={
                  state.optionsState[column.name] ? '' : 'hideFilterOptions'
                }
              >
                <InputGroup.Text>{column.name}</InputGroup.Text>
                <InputGroup.Text>Min</InputGroup.Text>
                <FormControl
                  step={"any"}
                  aria-label="min"
                  name={`${column.name}__TRUST-ME-QOD__min`}
                  type="number"
                  min={column.minValue}
                  max={column.maxValue}
                  placeholder={column.minValue?.toString()}
                />
                <InputGroup.Text>Max</InputGroup.Text>
                <FormControl
                  step={"any"}
                  aria-label="max"
                  name={`${column.name}__TRUST-ME-QOD__max`}
                  type="number"
                  min={column.minValue}
                  max={column.maxValue}
                  placeholder={column.maxValue?.toString()}
                />
              </InputGroup>
            );
          } else {
            fields = (
              <InputGroup
                className={
                  state.optionsState[column.name] ? '' : 'hideFilterOptions'
                }
              >
                <InputGroup.Text>{column.name}</InputGroup.Text>
                <Form.Select name={`${column.name}__TRUST-ME-QOD__textValues`} multiple>
                  {column.uniqueValues?.map((value) => {
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </Form.Select>
              </InputGroup>
            );
          }
          return fields;
        })}
      </>
    );
  }

  function toggleFilter(name: string): void {
    let optionState = {
      ...state.optionsState,
      [name]: !state.optionsState[name],
    };
    setState({
      ...state,
      optionsState: optionState,
    });
  }

  return (
    <Modal show={props.show} onHide={props.callbackClose}>
      <Form onSubmit={props.callbackSubmit}>
        <Modal.Header>
          <Modal.Title>Select Filter Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DropdownButton id="add-filter-dropdown" title="Add Filter">
            {filterDropdownChildren()}
          </DropdownButton>
          {filterOptionsList()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.callbackClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Filter
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FilterDataModal;
