import { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react';
import { Button, Form, FormGroup, InputGroup, Modal } from 'react-bootstrap';

interface CleanDataModalProps {
  callbackSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  callbackClose: () => void;
  show: boolean;
}

enum HANDLE_NULL_METHOD {
  MEAN = 'mean',
  MEDIAN = 'median',
  MODE = 'mode',
  DROP = 'remove',
  INTERPOLATE = 'interpolate',
}

enum HANDLE_OUTLIER_METHOD {
  MEAN = 'mean',
  MEDIAN = 'median',
  MODE = 'mode',
  DROP = 'remove',
}

const CleanDataModal: FunctionComponent<CleanDataModalProps> = (
  props: CleanDataModalProps
) => {
  const initialState: {
    cleanNull?: boolean;
    cleanOutliers?: boolean;
    cleanNullSelect?: HANDLE_NULL_METHOD;
    cleanOutlierSelect?: HANDLE_OUTLIER_METHOD;
    cleanDuplicate: boolean;
    cleanStructure: boolean;
  } = {
    cleanNull: false,
    cleanOutliers: false,
    cleanNullSelect: undefined,
    cleanOutlierSelect: undefined,
    cleanDuplicate: false,
    cleanStructure: false,
  };

  const [state, setState] = useState(initialState);

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const target = event.target;
    setState({ ...state, [target.name]: target.value });
  }

  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    setState({ ...state, [target.name]: target.checked });
  }

  return (
    <Modal show={props.show} onHide={props.callbackClose}>
      <Form onSubmit={props.callbackSubmit}>
        <Modal.Header>
          <Modal.Title>Select Clean Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="cleanNullGroup">
            <InputGroup>
              <InputGroup.Text>Clean Null Values</InputGroup.Text>
              <Form.Select
                name="cleanNullSelect"
                disabled={!state.cleanNull}
                value={state.cleanNullSelect}
                onChange={handleSelectChange}
              >
                <option value={HANDLE_NULL_METHOD.MEDIAN}>With Median</option>
                <option value={HANDLE_NULL_METHOD.MODE}>With Mode</option>
                <option value={HANDLE_NULL_METHOD.MEAN}>With Mean</option>
                <option value={HANDLE_NULL_METHOD.DROP}>Remove row</option>
                <option value={HANDLE_NULL_METHOD.INTERPOLATE}>
                  Interpolate values
                </option>
              </Form.Select>
              <InputGroup.Checkbox
                name="cleanNull"
                onChange={handleCheckboxChange}
                checked={state?.cleanNull}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="cleanOutlierGroup">
            <InputGroup>
              <InputGroup.Text>Clean Outliers</InputGroup.Text>
              <Form.Select
                name="cleanOutlierSelect"
                disabled={!state.cleanOutliers}
                value={state.cleanOutlierSelect}
                onChange={handleSelectChange}
              >
                <option value={HANDLE_OUTLIER_METHOD.MEDIAN}>With Median</option>
                <option value={HANDLE_OUTLIER_METHOD.MODE}>With Mode</option>
                <option value={HANDLE_OUTLIER_METHOD.MEAN}>With Mean</option>
                <option value={HANDLE_OUTLIER_METHOD.DROP}>Remove row</option>
              </Form.Select>
              <InputGroup.Checkbox
                name="cleanOutliers"
                onChange={handleCheckboxChange}
                checked={state?.cleanOutliers}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup controlId="cleanDuplicateGroup">
            <InputGroup>
              <InputGroup.Text>Remove Duplicate Rows</InputGroup.Text>
              <InputGroup.Checkbox
                name="cleanDuplicate"
                onChange={handleCheckboxChange}
                checked={state?.cleanDuplicate}
              />
            </InputGroup>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.callbackClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Clean
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CleanDataModal;