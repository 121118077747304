import { FormEvent, FunctionComponent } from 'react';
import { Button, Form, FormGroup, InputGroup, Modal } from 'react-bootstrap';

interface ScaleDataModalProps {
  callbackSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  callbackClose: () => void;
  show: boolean;
}

export enum SCALING_METHOD {
  STANDARDIZATION = 'Standardization',
  NORMALIZATION = 'Normalization',
}

const ScaleDataModal: FunctionComponent<ScaleDataModalProps> = (
  props: ScaleDataModalProps
) => {
  return (
    <Modal show={props.show} onHide={props.callbackClose}>
      <Form onSubmit={props.callbackSubmit}>
        <Modal.Header>
          <Modal.Title>Select Scaling Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="scalingSelectGroup">
            <InputGroup>
              <InputGroup.Text>Scaling Method</InputGroup.Text>
              <Form.Select name="scalingMethodSelect">
                <option value={SCALING_METHOD.NORMALIZATION}>
                  Normalization
                </option>
                <option value={SCALING_METHOD.STANDARDIZATION}>
                  Standardization
                </option>
              </Form.Select>
            </InputGroup>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.callbackClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Scale
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ScaleDataModal;
