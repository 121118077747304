import { FormEvent, FunctionComponent, useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';

interface PredictionInputProps {
  callbackSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
}

const PredictionInput: FunctionComponent<PredictionInputProps> = (
  props: PredictionInputProps
) => {
  const initialState: {
    isLoading: boolean;
  } = {
    isLoading: false,
  };

  const [state, setState] = useState(initialState);

  async function submitFunc(event: FormEvent<HTMLFormElement>) {
    setState({ isLoading: true });
    await props.callbackSubmit(event);
    setState({ isLoading: false });
  }

  return (
    <div>
      <h4>Example AI Call</h4>
      <Form onSubmit={submitFunc}>
        <FormGroup controlId="failGroup">
          <InputGroup>
            <InputGroup.Text>Fail</InputGroup.Text>
            <InputGroup.Checkbox id="failed-input" value="" />
          </InputGroup>
        </FormGroup>
        <FormGroup controlId="g1Group">
          <InputGroup>
            <InputGroup.Text>G1</InputGroup.Text>
            <FormControl type="number" name="g1-input" />
          </InputGroup>
        </FormGroup>
        <FormGroup controlId="g2Group">
          <InputGroup>
            <InputGroup.Text>G2</InputGroup.Text>
            <FormControl type="number" name="g2-input" />
          </InputGroup>
        </FormGroup>
        <Button variant="primary" type="submit" disabled={state.isLoading}>
          Call API
        </Button>
      </Form>
    </div>
  );
};

export default PredictionInput;
