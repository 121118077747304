import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';

import './OptionSelectHint.css';

const OptionSelectHint: FunctionComponent = () => {
  return (
    <div className="option-select-container">
      <span className="option-select-arrow">
        <FontAwesomeIcon
          className="option-select-icon"
          icon={faArrowLeft}
          style={{ width: '75px', height: '75px' }}
        ></FontAwesomeIcon>
      </span>
      <span className="option-select-text">Select an option to start</span>
    </div>
  );
};

export default OptionSelectHint;
