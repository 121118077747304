import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { FormCheck, Spinner, Table } from 'react-bootstrap';
import { AppContext, PROCESSING_METHOD } from '../../state/state';
import Button from 'react-bootstrap/Button';

import groupBy from 'lodash/groupBy';

import './DataInfoTable.css';
import { ACTIONS } from '../../state/actions';
import InfoGraph from '../InfoGraph/InfoGraph';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import CleanDataModal from '../CleanDataModal/CleanDataModal';
import FilterDataModal from '../FilterDataModal/FilterDataModal';
import ScaleDataModal from '../ScaleDataModal/ScaleDataModal';
import EncodeDataModal from '../EncodeDataModal/EncodeDataModal';

export type DataCleanApi = {
  '% of Duplicate rows removed': number;
  '% of NULL rows removed': number;
  'Number of rows after cleaning': number;
  'Original number of rows': number;
  '% of Duplicate rows': number;
  '% of Good rows': number;
  '% of NULL rows': number;
  '% of Outliers': number;
};

export type DataFilterApi = {
  'Number of rows after filtering': number;
  'Original number of rows': number;
  '% of Duplicate rows': number;
  '% of Good rows': number;
  '% of NULL rows': number;
};

interface DataInfoTableProps {}

const DataInfoTable: FunctionComponent<DataInfoTableProps> = () => {
  const context = useContext(AppContext);

  const data = context!.state?.qod;

  const initialState: {
    isLoading: boolean;
    showCleanModal: boolean;
    showFilterModal: boolean;
    showScalingModal: boolean;
    showEncodingModal: boolean;
    forceDownload: boolean;
  } = {
    isLoading: false,
    showCleanModal: false,
    showFilterModal: false,
    showScalingModal: false,
    showEncodingModal: false,
    forceDownload: false,
  };

  const [state, setState] = useState(initialState);

  function openCleanModal() {
    setState({ ...state, showCleanModal: true });
  }

  function openFilterModal() {
    setState({ ...state, showFilterModal: true });
  }

  function openScalingModal() {
    setState({ ...state, showScalingModal: true });
  }

  function openEncodingModal() {
    setState({ ...state, showEncodingModal: true });
  }

  function handleClose() {
    setState({
      ...state,
      showCleanModal: false,
      showFilterModal: false,
      showScalingModal: false,
      showEncodingModal: false,
    });
  }

  function LoaderOverlay() {
    if (state.isLoading) {
      return (
        <span className="loadingOverlay">
          <Spinner animation="border" />
          <span className="loadingText">Loading...</span>
        </span>
      );
    }
  }

  async function cleanData(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    setState({ ...state, isLoading: true });

    const newForm = new FormData();
    const csvFile = context!.state?.currentCsv;
    const csvDescription = context!.state?.qod?.description;

    if (!csvFile || !csvDescription) {
      setState({ ...state, isLoading: false, showCleanModal: false });
      return;
    }
    newForm.append('filename', csvFile.name);
    newForm.append('description', csvDescription);
    newForm.append(
      'removeDuplicate',
      ((event.target as HTMLFormElement)[4] as HTMLInputElement).checked
        ? '1'
        : '0'
    );
    newForm.append(
      'Null_FillMethod',
      ((event.target as HTMLFormElement)[1] as HTMLInputElement).checked
        ? ((event.target as HTMLFormElement)[0] as HTMLInputElement).value
        : 'nothing'
    );
    newForm.append(
      'outlierMethod',
      ((event.target as HTMLFormElement)[3] as HTMLInputElement).checked
        ? ((event.target as HTMLFormElement)[2] as HTMLInputElement).value
        : 'nothing'
    );
    const requestOptions = {
      method: 'POST',
      body: newForm,
    };
    const url = 'https://trustme-data-quality.herokuapp.com/data-cleaning';

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      console.log('Cleaned Data: ', data);
      setState({ ...state, isLoading: false, showCleanModal: false });
      context.dispatch!({ type: ACTIONS.QOD_CLEAN_INFO, payload: { data } });
    } catch (err) {
      console.error(err);
      setState({ ...state, isLoading: false, showCleanModal: false });
    }
  }

  function eitlDisclaimer() {
    if (!checkAllProcessing()) {
      return (
        <div className="eitlBox">
          <FormCheck onChange={updateDownloadAgreement}></FormCheck>
          <span>
            <em>Expert-in-the-Loop (EitL) acknowledgement:</em> I acknowledge
            that I have skipped one or more of the data processing steps.
          </span>
        </div>
      );
    }
  }

  async function filterData(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    setState({ ...state, isLoading: true });
    const csvFile = context!.state?.currentCsv;

    if (!csvFile) {
      return;
    }

    const fieldGroup = groupBy(
      Array.prototype.slice.call((event.target as HTMLFormElement).elements),
      (element) => {
        return element.name.split('__TRUST-ME-QOD__')[0];
      }
    );

    const conditions = Object.entries(fieldGroup)
      .map(([key, value]) => {
        let dtype: string;
        let rules:
          | { max: number | undefined; min: number | undefined }
          | { values: string[] }
          | undefined;
        if (key && key !== '') {
          if (value[0].type === 'select-multiple') {
            const values = Array.prototype.slice
              .call(value[0].options as HTMLOptionsCollection)
              .filter((option: HTMLOptionElement) => {
                return option.selected;
              })
              .map((option: HTMLOptionElement) => {
                return option.value;
              });

            dtype = 'object';
            if (!values || values.length === 0) {
              rules = undefined;
            } else {
              rules = { values };
            }
          } else {
            dtype = 'float64';
            rules = {
              max:
                parseInt(
                  value.find(
                    (input) => input.name.split('__TRUST-ME-QOD__')[1] === 'max'
                  ).value
                ) || undefined,
              min:
                parseInt(
                  value.find(
                    (input) => input.name.split('__TRUST-ME-QOD__')[1] === 'min'
                  ).value
                ) || undefined,
            };
            if (!rules.max && !rules.min) {
              rules = undefined;
            }
          }

          return {
            column: key,
            dtype,
            rules,
          };
        }
        return null;
      })
      .filter((condition) => {
        return condition?.rules;
      });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filename: csvFile.name,
        conditions,
      }),
    };
    const url = 'https://trustme-data-quality.herokuapp.com/data-filter';

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setState({ ...state, isLoading: false, showFilterModal: false });
      context.dispatch!({ type: ACTIONS.QOD_FILTER_INFO, payload: { data } });
    } catch (err) {
      console.error(err);
      setState({ ...state, isLoading: false, showFilterModal: false });
    }
  }

  async function scaleData(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    setState({ ...state, isLoading: true });
    const newForm = new FormData();
    const csvFile = context!.state?.currentCsv;

    const scalingValue = (event.currentTarget[0] as HTMLInputElement).value;

    if (!csvFile) {
      return;
    }
    newForm.append('filename', csvFile.name);
    newForm.append('scaling_method', scalingValue);
    const requestOptions = {
      method: 'POST',
      body: newForm,
    };
    const url = 'https://trustme-data-quality.herokuapp.com/data-scaling';

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setState({ ...state, isLoading: false, showScalingModal: false });
      context.dispatch!({
        type: ACTIONS.QOD_SCALE_INFO,
        payload: { data, method: scalingValue },
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, isLoading: false, showScalingModal: false });
    }
  }

  async function encodeData(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();
    setState({ ...state, isLoading: true });
    const newForm = new FormData();
    const csvFile = context!.state?.currentCsv;

    const encodingValue = (event.currentTarget[0] as HTMLInputElement).value;

    if (!csvFile) {
      return;
    }
    newForm.append('filename', csvFile.name);
    const requestOptions = {
      method: 'POST',
      body: newForm,
    };
    const url = 'https://trustme-data-quality.herokuapp.com/data-encoding';

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setState({ ...state, isLoading: false, showEncodingModal: false });
      context.dispatch!({
        type: ACTIONS.QOD_ENCODE_INFO,
        payload: { data, method: encodingValue },
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, isLoading: false, showEncodingModal: false });
    }
  }

  async function fetchEncodedCsv() {
    const csvFile = context!.state?.currentCsv;
    let url;

    switch (context!.state?.qod?.lastProcessingMethod) {
      case PROCESSING_METHOD.CLEAN:
        url =
          'https://trustme-data-quality.herokuapp.com/data-clean-preview/' +
          csvFile?.name;
        break;

      case PROCESSING_METHOD.FILTER:
        url =
          'https://trustme-data-quality.herokuapp.com/data-filter-preview/' +
          csvFile?.name;
        break;
      case PROCESSING_METHOD.SCALE:
        url =
          'https://trustme-data-quality.herokuapp.com/data-scale-preview/' +
          csvFile?.name;
        break;
      case PROCESSING_METHOD.ENCODE:
      default:
        url =
          'https://trustme-data-quality.herokuapp.com/data-encode-preview/' +
          csvFile?.name;
        break;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    };

    try {
      const response = await fetch(url, requestOptions);
      const blob = await response.blob();

      const newUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = newUrl;
      const csvFileNameArray = csvFile?.name.split('.');
      csvFileNameArray?.pop();
      const csvFileName = csvFileNameArray?.join('.');
      link.setAttribute('download', csvFileName + '-preview.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  }

  function updateDownloadAgreement(event: ChangeEvent<HTMLInputElement>) {
    setState({ ...state, forceDownload: event.target.checked });
  }

  function cleanedDataTable() {
    if (data?.cleaned) {
      return (
        <>
          <tr>
            <th colSpan={2}>Cleaned Data</th>
          </tr>
          <tr>
            <td>Number of rows in cleaned data:</td>
            <td>{data?.cleaned?.remainingRows}</td>
          </tr>
          <tr>
            <td>Null rows found:</td>
            <td>{data?.cleaned?.removedNullPercent}%</td>
          </tr>
          <tr>
            <td>Duplicate rows found:</td>
            <td>{data?.cleaned?.removedDuplicatePercent}%</td>
          </tr>
          <tr>
            <td>Outliers:</td>
            <td>{data?.cleaned?.outliers}%</td>
          </tr>
        </>
      );
    }
  }

  function filteredDataTable() {
    if (data?.filtered) {
      return (
        <>
          <tr>
            <th colSpan={2}>Filtered Data</th>
          </tr>
          <tr>
            <td>Number of rows in filtered data:</td>
            <td>{data?.filtered?.remainingRows}</td>
          </tr>
        </>
      );
    }
  }

  function scaledDataTable() {
    if (data?.scaled) {
      return (
        <>
          <tr>
            <th colSpan={2}>Scaled Data</th>
          </tr>
          <tr>
            <td>Data scaled via:</td>
            <td>{data?.scaled.method}</td>
          </tr>
        </>
      );
    }
  }

  function encodedDataTable() {
    if (data?.encoded) {
      return (
        <>
          <tr>
            <th colSpan={2}>Encoded Data</th>
          </tr>
          <tr>
            <td>Data encoded:</td>
            <td>Automatically</td>
          </tr>
        </>
      );
    }
  }

  function checkAllProcessing(): boolean {
    return (
      !!data?.filtered && !!data?.cleaned && !!data.scaled && !!data.encoded
    );
  }

  function dataForGraph() {
    const fullData = [
      {
        quantity: data!.current.duplicatePercent,
        label: 'Duplicate Data',
      },
      { quantity: data!.current.nullPercent, label: 'Null Data' },
      { quantity: data!.current.qualityPercent, label: 'Good Data' },
      { quantity: data!.current.outlierPercent, label: 'Outliers' },
    ];

    const cleanData = fullData.filter((row) => {
      return row.quantity > 0;
    });

    return cleanData;
  }

  return (
    <div className="qodMainContainer">
      {LoaderOverlay()}
      <span className="graphContainer">
        <InfoGraph width={500} height={500} dataset={dataForGraph()} />
      </span>
      <span className="dataTableContainer">
        <h2>Dataset "{data?.description}"</h2>
        <h4>Data Quality</h4>
        <Table hover variant="dark">
          <tbody>
            <tr>
              <th colSpan={2}>Current Data</th>
            </tr>
            <tr>
              <td>Number of rows:</td>
              <td>{data?.current.rows}</td>
            </tr>
            <tr>
              <td>Number of columns:</td>
              <td>{data?.current.columns}</td>
            </tr>
            <tr>
              <td>Data quality:</td>
              <td>{data?.current.qualityPercent}%</td>
            </tr>
            <tr>
              <th colSpan={2}>Initial Data</th>
            </tr>
            <tr>
              <td>Number of rows:</td>
              <td>{data?.initial.rows}</td>
            </tr>
            <tr>
              <td>Number of columns:</td>
              <td>{data?.initial.columns}</td>
            </tr>
            <tr>
              <td>Duplicate rows:</td>
              <td>{data?.initial.duplicatePercent}%</td>
            </tr>
            <tr>
              <td>Null rows:</td>
              <td>{data?.initial.nullPercent}%</td>
            </tr>
            <tr>
              <td>Good rows:</td>
              <td>{data?.initial.goodPercent}%</td>
            </tr>
            <tr>
              <td>Outliers:</td>
              <td>{data?.initial.outlierPercent}%</td>
            </tr>
            {data?.cleaned ? cleanedDataTable() : <></>}
            {data?.filtered ? filteredDataTable() : <></>}
            {data?.scaled ? scaledDataTable() : <></>}
            {data?.encoded ? encodedDataTable() : <></>}
          </tbody>
        </Table>
        <h4>Automated Data Processing</h4>
        <div className="processingButtonsContainer">
          <Button onClick={openCleanModal}>
            Clean{' '}
            <FontAwesomeIcon
              icon={data?.cleaned ? faCheck : faTimes}
            ></FontAwesomeIcon>
          </Button>
          <Button onClick={openFilterModal}>
            Filter{' '}
            <FontAwesomeIcon
              icon={data?.filtered ? faCheck : faTimes}
            ></FontAwesomeIcon>
          </Button>
          <Button onClick={openScalingModal}>
            Scale{' '}
            <FontAwesomeIcon
              icon={data?.scaled ? faCheck : faTimes}
            ></FontAwesomeIcon>
          </Button>
          <Button onClick={openEncodingModal}>
            Encode{' '}
            <FontAwesomeIcon
              icon={data?.encoded ? faCheck : faTimes}
            ></FontAwesomeIcon>
          </Button>
        </div>
        <h4>Preview CSV Data</h4>
        {eitlDisclaimer()}
        <Button
          className="downloadButton"
          onClick={fetchEncodedCsv}
          disabled={!checkAllProcessing() && !state.forceDownload}
        >
          Download
        </Button>
      </span>

      <CleanDataModal
        callbackSubmit={cleanData}
        callbackClose={handleClose}
        show={state.showCleanModal}
      ></CleanDataModal>

      <FilterDataModal
        callbackSubmit={filterData}
        callbackClose={handleClose}
        show={state.showFilterModal}
        columnData={data!.initial.columnDetails}
      ></FilterDataModal>

      <ScaleDataModal
        callbackSubmit={scaleData}
        callbackClose={handleClose}
        show={state.showScalingModal}
      ></ScaleDataModal>

      <EncodeDataModal
        callbackSubmit={encodeData}
        callbackClose={handleClose}
        show={state.showEncodingModal}
      ></EncodeDataModal>
    </div>
  );
};

export default DataInfoTable;
