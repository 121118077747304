import { useContext } from 'react';
import { AppContext } from '../../state/state';
import './Qot.css';

function QotPage() {
  const context = useContext(AppContext);
  const qod = context!.state?.qod;

  function canDisplay() {
    if (!qod) {
      return (
        <div className="qod-message">
          <h3>Please process a file via QoD before continuing</h3>
        </div>
      );
    } else {
    }
  }

  return <>{canDisplay()}</>;
}

export default QotPage;
