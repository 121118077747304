import { FormEvent, useContext, useState } from 'react';
import JupyterUploadForm from '../../components/JupyterUploadForm/JupyterUploadForm';
import JupyterViewer from '../../components/JupyterViewer/JupyterViewer';
import PredictionInput from '../../components/PredictionInput/PredictionInput';
import { AppContext } from '../../state/state';
import './Ai.css';
import PageLogo from '../../assets/PageLogo.png';

function AiPage() {
  const context = useContext(AppContext);

  const initialState: {
    prediction: {
      input: {
        fail: boolean;
        g1: number;
        g2: number;
      };
      result?: {
        prediction: string;
      };
    };
  } = {
    prediction: {
      input: {
        fail: false,
        g1: 0,
        g2: 0,
      },
    },
  };

  const [state, setState] = useState(initialState);

  function jupyterComponent() {
    if (!context!.state?.ai) {
      return <JupyterUploadForm />;
    } else {
      return <JupyterViewer rawIpynb={context!.state?.ai.rawIpynb} />;
    }
  }

  async function submitPrediction(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.stopPropagation();

    const failValue = (event.currentTarget[0] as HTMLInputElement).checked
      ? 1
      : 0;
    const g1Value = Number((event.currentTarget[1] as HTMLInputElement).value);
    const g2Value = Number((event.currentTarget[2] as HTMLInputElement).value);

    const url = `https://apitrustme.herokuapp.com/predict?fail=${failValue}&g1=${g1Value}&g2=${g2Value}`;

    const requestOptions = {
      method: 'GET',
    };

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setState({ ...state, prediction: { ...state.prediction, result: data } });
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  }

  function predictionComponent() {
    if (!state.prediction.result) {
      return <PredictionInput callbackSubmit={submitPrediction} />;
    } else {
      return (
        <div>
          <h4>
            Prediction for a query of Fail = {state.prediction.input.fail}, G1 ={' '}
            {state.prediction.input.g1} , G2 = {state.prediction.input.g2}
          </h4>
          <h4>{state.prediction.result.prediction}</h4>
        </div>
      );
    }
  }

  return (
    <div className="ai-container">
      {jupyterComponent()}
      <div className="divider"></div>
      {predictionComponent()}
    </div>
  );
}

export default AiPage;
