import { FunctionComponent, FormEvent, useContext, useState } from 'react';

import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { AppContext } from '../../state/state';
import { ACTIONS } from '../../state/actions';
import { Loader } from '../Loader/Loader';

import './DataInfoForm.css';

export enum APIINTERACTION {
  INFO,
  CLEAN,
  FILTER,
  SCALE,
  ENCODE,
}

interface DataInfoFormProps {
  type: APIINTERACTION;
}

export type DataInfoApi = {
  '% of Duplicate rows': number;
  '% of Good rows': number;
  '% of NULL rows': number;
  '% of Outliers': number;
  'Column details': {
    'Column name': string;
    'Data Type': 'object' | 'int64' | 'float64';
    Type: 'Unique' | 'Min_Max';
    'Max value'?: number;
    'Min value'?: number;
    'Unique Values': string[];
  }[];
  'Number of columns': number;
  'Number of rows': number;
};

const DataInfoForm: FunctionComponent<DataInfoFormProps> = ({
  type,
}: DataInfoFormProps) => {
  const context = useContext(AppContext);

  const initialState: { isLoading?: boolean; error?: string } = {
    isLoading: false,
    error: undefined,
  };

  const [state, setState] = useState(initialState);

function errorMessage()
{
  return state.error ? (<Alert variant='danger'>{state.error}</Alert>) : null;
};

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    setState({ ...state, isLoading: true, error: undefined });

    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData(event.currentTarget);

    const file = formData.get('csvFile') as File;
    const description = formData.get('description') as string;

    //alert(file.type);
    

    if(file.type !== "text/csv" && file.type !== "application/vnd.ms-excel")
    {
      console.error("Invalid File Type");
      setState({ ...state, error: "Invalid File Type", isLoading: false});

      return;
    }

    const newForm = new FormData();

    newForm.append('data', file, file.name);

    let requestOptions;
    let url: string;

    switch (type) {
      case APIINTERACTION.INFO:
      default:
        newForm.append('description', description);
        requestOptions = {
          method: 'POST',
          body: newForm,
        };
        url = 'https://trustme-data-quality.herokuapp.com/data-info';
        break;
    }

    try {
      const response = await fetch(url, requestOptions);
      const data = (await response.json()) as DataInfoApi;
      setState({ ...state, isLoading: false });
      context.dispatch!({
        type: ACTIONS.QOD_SET_INFO,
        payload: { data, description, file },
      });
    } catch (err) {
      console.error(err);
      setState({ ...state, isLoading: false });
    }
  }

  return (
    <div className="info-form-container">
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel>Upload CSV File</FormLabel>
          <FormControl
            name="csvFile"
            required
            type="file"
            placeholder="Enter Username"
            disabled={state.isLoading}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel></FormLabel>
          <FormControl
            name="description"
            type="text"
            placeholder="Enter File Description"
            disabled={state.isLoading}
          />
        </FormGroup>
{errorMessage()}
        <Button
          id="dataInfoButton"
          variant="primary"
          type="submit"
          disabled={state.isLoading}
        >
          {state.isLoading ? (
            <Loader text="Loading..." />
          ) : (
            'Data Quality Check'
          )}
        </Button>
      </Form>
    </div>
  );
};

export default DataInfoForm;