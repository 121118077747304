import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import LoginPage from './pages/Login/Login.page';
import HomePage from './pages/Home/Home.page';
import QodPage from './pages/Qod/Qod.page';
import QotPage from './pages/Qot/Qot.page';
import AiPage from './pages/Ai/Ai.page';
import NavigationRail from './components/NavigationRail/NavigationRail';

import { ACTIONS } from './state/actions';
import { AppContext, initialState } from './state/state';
import { reducer } from './state/reducers';

import { useReducer, useContext, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router';
import {
  faBrain,
  faSignOutAlt,
  faTools,
  faRecycle,
} from '@fortawesome/free-solid-svg-icons';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import FloatingPageLogo from './components/FloatingPageLogo/FloatingPageLogo';

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const context = useContext(AppContext);
  const location = useLocation();
  if (!context.state?.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Router>
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<HomePage />} />
            <Route path="/qod" element={<QodPage />} />
            <Route path="/qot" element={<QotPage />} />
            <Route path="/ai" element={<AiPage />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

function Reset() {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch!({ type: ACTIONS.RESET, payload: null });
    navigate('/');
  });

  return <div>Clearing data...</div>;
}

function Logout() {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch!({ type: ACTIONS.LOGOUT, payload: null });
    navigate('/login');
  });

  return <div>Logging you out...</div>;
}

function FloatingLogo() {
  const location = useLocation();
  if (location.pathname !== '/') {
    return <FloatingPageLogo />;
  }
}

function SharedLayout() {
  const menuItems = [
    {
      label: 'QOD',
      icon: faTools,
      navigateTo: '/qod',
    },
    {
      label: 'QOT/XAI',
      icon: faBrain,
      navigateTo: '/ai',
    },
    {
      label: 'Reset',
      icon: faRecycle,
      navigateTo: '/reset',
    },
    {
      label: 'Logout',
      icon: faSignOutAlt,
      navigateTo: '/logout',
    },
  ];

  return (
    <RequireAuth>
      <div className="app-with-menu-container">
        {FloatingLogo()}
        <NavigationRail menuItems={menuItems} />
        <Outlet />
      </div>
    </RequireAuth>
  );
}

export default App;
