import { FormEvent, FunctionComponent } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';

interface EncodeDataModalProps {
  callbackSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  callbackClose: () => void;
  show: boolean;
}

export enum ENCODING_METHOD {
  AUTO = 'Automatic',
  MANUAL = 'Manual',
}

const EncodeDataModal: FunctionComponent<EncodeDataModalProps> = (
  props: EncodeDataModalProps
) => {
  return (
    <Modal show={props.show} onHide={props.callbackClose}>
      <Form onSubmit={props.callbackSubmit}>
        <Modal.Header>
          <Modal.Title>Select Encoding Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <InputGroup.Text>Encoding Method</InputGroup.Text>
            <InputGroup.Text>Automatic</InputGroup.Text>
          </InputGroup>
          <Form.Select
            name="encodingMethodSelect"
            disabled={true}
            hidden={true}
          >
            <option value={ENCODING_METHOD.AUTO}>Automatic</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.callbackClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Encode
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EncodeDataModal;
