import { AppAction } from './actions';
import { createContext } from 'react';
import { SCALING_METHOD } from '../components/ScaleDataModal/ScaleDataModal';
import { ENCODING_METHOD } from '../components/EncodeDataModal/EncodeDataModal';

export type CleanedData = {
  removedDuplicatePercent: number;
  removedNullPercent: number;
  remainingRows: number;
  outliers: number;
};

export type FilteredData = {
  remainingRows: number;
};

export type ColumnDetails = {
  name: string;
  datatype: 'object' | 'int64' | 'float64';
  type: 'unique' | 'min_max';
  mean: string | number;
  median: string | number;
  mode: string | number;
  uniqueValues: string[];
  maxValue?: number;
  minValue?: number;
};

export type ScaledColumn = {
  name: string;
  max: number;
  min: number;
  mean: number;
  standardDeviation: number;
};

export type ScaledData = {
  beforeScaling: ScaledColumn[];
  afterScaling: ScaledColumn[];
  method: SCALING_METHOD;
};

export enum PROCESSING_METHOD {
  CLEAN,
  FILTER,
  SCALE,
  ENCODE,
}

export type QodData = {
  description: string;
  current: {
    rows: number;
    columns: number;
    qualityPercent: number;
    duplicatePercent: number;
    nullPercent: number;
    outlierPercent: number;
  };
  initial: {
    rows: number;
    columns: number;
    duplicatePercent: number;
    nullPercent: number;
    goodPercent: number;
    outlierPercent: number;
    columnDetails: ColumnDetails[];
  };
  cleaned?: CleanedData;
  filtered?: FilteredData;
  scaled?: ScaledData;
  encoded?: {
    columns: string[];
    method: ENCODING_METHOD;
  };
  lastProcessingMethod?: PROCESSING_METHOD;
};

export type AIData = {
  rawIpynb: any;
};

export type AppState = {
  isAuthenticated: boolean;
  username: string | null;
  token: string | null;
  currentCsv: File | null;
  qod: QodData | null;
  ai: AIData | null;
};

export type ApplicationContext = {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
};

export const initialState: AppState = {
  isAuthenticated: !!localStorage.getItem('token'),
  username: localStorage.getItem('username') || null,
  token: localStorage.getItem('token') || null,
  qod: null,
  ai: null,
  currentCsv: null,
};

export const AppContext = createContext<Partial<ApplicationContext>>({});